import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { mapXlsRichDescriptionsToHtml, universalDateParser } from "@utils";
import { Alert } from "@components";
import { Grid, LinearProgress } from "@material-ui/core";
import { Workbook } from "exceljs";
import { XlsFile } from "@assets";
import { format } from "date-fns";
import useStyle from "./Steps.style";
import { useProjectCreatorStore } from "@hooks";

export const Import = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const creator = useProjectCreatorStore();

  const [isLoading, setLoading] = useState(false);
  const [alertVisible, showAlert] = useState(false);
  const [progress, setProgress] = useState(0);

  const fileGetter = async (fileList) => {
    const files = [];
    setProgress(0);
    setLoading(true);

    if (fileList || fileList.length) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        files.push(file);
      }
    }

    const reader = new window.FileReader();
    reader.onload = async () => {
      const wb = new Workbook();
      await wb.xlsx.load(reader.result);

      const htmlColumns = [];
      
      creator.setXlsData(
        wb.worksheets
          .filter((worksheet) => worksheet.columns)
          .map((worksheet) => {
            const dimensions = [
              worksheet.dimensions.right,
              worksheet.dimensions.bottom,
            ];

            let data = [...new Array(dimensions[1])].map(() =>
              [...new Array(dimensions[0])].map(() => "")
            );
            worksheet.eachRow((row, rowNumber) => {
              if (!row) {
                return;
              }
              if (rowNumber <= dimensions[1]) {
                row.eachCell((cell, colNumber) => {
                  if (colNumber <= dimensions[0]) {
                    let v = "";
                    if (cell?.value || cell?.value === 0) {
                      if (typeof cell.value === "object") {
                        if (cell.value instanceof Date)
                          v = format(
                            universalDateParser(cell.value),
                            cell?.numFmt?.replace("mm", "MM") || "dd.MM.yyyy"
                          );
                        else if(cell.value.richText) {
                          if(!htmlColumns.includes(colNumber))
                            htmlColumns.push(colNumber)
                          v = mapXlsRichDescriptionsToHtml(cell.value.richText)
                        }
                        else
                          v =
                            cell.result === "#REF!" ||
                            cell.value.result === "#REF!"
                              ? ""
                              : cell.result || cell.value.result;
                      } else if(typeof cell.value === "string" && cell.style?.font) {
                        if(!htmlColumns.includes(colNumber))
                          htmlColumns.push(colNumber)
                        v = mapXlsRichDescriptionsToHtml([
                          { text: cell.value, ...cell.style }
                        ])
                      } else v = cell.value;
                    }

                    if (typeof v === "number")
                      v = parseFloat(v.toPrecision(12));

                    if (
                      cell?.numFmt &&
                      cell.numFmt.match(/(0.?0*$)/g) &&
                      cell?.value
                    ) {
                      const p = Math.pow(
                        10,
                        (cell.numFmt + ".").split(".")[1].length
                      );
                      v = Math.round(v * p) / p;
                    }

                    data[rowNumber - 1][colNumber - 1] = v ? v.toString() : "";
                  }
                });
              }
            });

            return {
              title: worksheet.name,
              parsed: false,
              // used: i===0, //wb.worksheets.length === 1,
              data,
              colWidths: worksheet.columns
                .filter((r, i) => i < dimensions[0])
                .map((c) => (c?.width ? c?.width * 8 : 0)),
              columns: worksheet.columns
                .filter((r, i) => i < dimensions[0])
                .map((_, i) => htmlColumns.includes(i+1)
                  ? { type: "html" }
                  : { type: "text" }
                ),
              mergeCells: Object.keys(worksheet._merges).reduce((p, c) => {
                const { left, right, top, bottom } = worksheet._merges[c];
                return { ...p, [c]: [right - left + 1, bottom - top + 1] };
              }, {}),
              minDimensions: dimensions,
            };
          }),
        fileList[0].name
      );
      setLoading(false);
    };
    reader.readAsArrayBuffer(files[0]);
    reader.onprogress = (p) => {
      setProgress(Math.min((p.loaded / p.total) * 100, 100));
    };
    reader.onerror = (e) => {
      // eslint-disable-next-line no-console
      console.error(e);
      showAlert(true);
      setLoading(false);
    };
  };

  const { getInputProps, getRootProps, open, isDragActive } = useDropzone({
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    },
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDropAccepted: fileGetter,
  });

  return (
    <Grid item container direction="column" className="overflow-hidden px-6">
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        {...getRootProps()}
        onClick={open}
        className={classes.dropzone}
      >
        <div className={classes.iconBackground}>
          <XlsFile style={{ fontSize: 60 }} />
        </div>
        {isLoading ? (
          <LinearProgress value={progress} variant="determinate" />
        ) : (
          <p className="font-bold">
            {isDragActive
              ? t("views.import.drop_active")
              : t("views.import.drop_inactive")}
          </p>
        )}
        {!isLoading && !isDragActive && (
          <p className="transparent-3 mt-1 ">
            {t("views.import.drop_inactive_sub")}
          </p>
        )}
        <input
          {...getInputProps()}
          className="display-block h-0 w-full"
          tabIndex="0"
          aria-label={t("views.import.drop_inactive")}
        />
      </Grid>
      <Alert
        title={t("views.import.import_error")}
        isOpen={alertVisible}
        onAccept={() => showAlert(false)}
        acceptText={t("common.close")}
      />
    </Grid>
  );
};
