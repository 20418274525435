import { useState } from "react";
import { bool, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { getDuplicateName } from "project-structure";
import { checkProjectCommentsQuery, duplicateProjectQuery, forceProjectSaveQuery } from "@query";
import { LOCKED_FEATURES } from "@utils";
import { useActiveProjectStore, useActiveProjectsWebsocket, useFeatureGuardian } from "@hooks";
import { Alert, MenuButton } from "@components";
import { FileCopy } from "@assets";
import { CircularProgress } from "@material-ui/core";

export const DuplicateProject = ({
  uuid,
  name,
  // hasComments,
}) => {
  
  const activeProjects = useActiveProjectStore();
  const socket = useActiveProjectsWebsocket();
  const { t } = useTranslation();
  
  const [isDuplicating, setDuplicate] = useState(false);
  const [commentDialogVisible, showCommentDialog] = useState(false);
  
  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.ESTIMATION,
  );
  
  const handleDuplicateProject = async () => {
    setDuplicate(true);
    const allowed = await checkAccess();
    if (!allowed) {
      setDuplicate(false);
      return;
    }
    const hasComments = await checkProjectCommentsQuery(uuid);
    if(hasComments)
      showCommentDialog(true);
    else
      await duplicateProject();
  }
  
  const handleDuplicateProjectWithComments = (copyComments) => async () => {
    showCommentDialog(false);
    await duplicateProject(copyComments);
  }
  
  const duplicateProject = async (copyComments) => {
    await forceProjectSaveQuery(uuid);
    const p = await duplicateProjectQuery(uuid, getDuplicateName(name), copyComments);
    socket.requestProjectDuplicate(uuid, p);
    activeProjects.addOrUpdateProject(p);
    
    setDuplicate(false);
  }
  
  return <>
    <MenuButton
      icon={isDuplicating ? <CircularProgress size={18} /> : <FileCopy />}
      onClick={handleDuplicateProject}
    >
      {t("common.duplicate")}
    </MenuButton>
    <FeatureAlert />
    
    <Alert
      isOpen={commentDialogVisible}
      title={t("views.project.comments_full")}
      onAccept={handleDuplicateProjectWithComments(true)}
      onCancel={handleDuplicateProjectWithComments(false)}
      acceptText={t("common.yes")}
      cancelText={t("common.no")}
    />
  </>
}

DuplicateProject.propTypes = {
  uuid: string.isRequired,
  name: string.isRequired,
  hasComments: bool,
}