import { bool, func, number, string } from "prop-types";
import { Grid, IconButton, Switch } from "@material-ui/core";
import { Tag, TextClearInput, TooltipIcon } from "@components";
import { useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Trash } from "@assets";

export const OverheadTitle = ({
  name,
  used,
  expanded,
  isGlobal,
  isFirstOnList,
  percent,
  setUsed,
  setName,
  onNameInputFocus,
  onRemove,
  useActions=true,
}) => {
  const { t } = useTranslation();
  
  const [inputValue, setInputValue] = useState(name);
  const [focused, setFocused] = useState(false);
  
  const handleFocus = () => {
    if(onNameInputFocus) {
      onNameInputFocus();
      return;
    }
    setFocused(true);
  }
  
  const handleSetName = () => {
    setFocused(false);
    if(!inputValue?.length)
      setInputValue(name);
    else
      setName(inputValue);
  }
  
  const handleSetUsed = (e) => {
    e.stopPropagation();
    setUsed?.(!used)
  }
  
  return (
    <Grid
      item container
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid
        item container
        alignItems="center"
        className="w-full"
        wrap="nowrap"
        // style={{ maxWidth: 380 }}
      >
        <TextClearInput
          multiline
          value={inputValue}
          focused={focused}
          onFocus={handleFocus}
          onChange={setInputValue}
          onAccept={handleSetName}
          acceptOnClickAway
          confirmOnEnter
          error={!inputValue?.length}
          placeholder={t(`views.editor.dialogs.overheads.name_placeholder`)}
          containerClass="name"
          className={classnames("mx-2 my-1")}
          maxLength={120}
        />
        {
          !expanded && typeof percent === "number" &&
          <span className="text-sm font-bold transparent-2">
            { `(${ percent }%)` }
          </span>
        }
        {
          isGlobal &&
          <>
            {
              isFirstOnList &&
              <TooltipIcon className="ml-1" color="primary">
                {t("views.editor.dialogs.overheads.info.global")}
              </TooltipIcon>
            }
            <Tag text={t(`views.editor.dialogs.overheads.global`)} className="ml-1" />
          </>
        }
      </Grid>
      {
        useActions &&
        <Grid
          item container
          alignItems="center"
          className="w-max"
          style={{ minWidth: 84 }}
        >
          {
            Boolean(onRemove) &&
            <IconButton onClick={onRemove} size="small" className="hoverIcon mr-2">
              <Trash color="error" />
            </IconButton>
          }
          <Switch
            color="primary"
            checked={used}
            onChange={handleSetUsed}
          />
        </Grid>
      }
    </Grid>
  );
};

OverheadTitle.propTypes = {
  used: bool.isRequired,
  name: string.isRequired,
  percent: number,
  setUsed: func,
  setName: func.isRequired,
  onRemove: func,
  onNameInputFocus: func,
  isGlobal: bool,
  isFirstOnList: bool,
  useActions: bool,
  expanded: bool,
};