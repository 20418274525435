import { useState } from "react";
import { bool, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { MenuButton } from "@components";
import { FileAdd } from "@assets";
import { CreateTemplateDialog } from "@dialogs";
import { checkProjectCommentsQuery } from "@query";

export const CreateTemplate = ({
  uuid,
  name,
  description,
  // hasComments,
}) => {
  
  const { t } = useTranslation();
  
  const [templateDialog, setTemplateDialog] = useState(false);
  const [hasComments, setHasComments] = useState(false);
  
  const handleDialogOpen = async () => {
    const has = await checkProjectCommentsQuery(uuid);
    setHasComments(has);
    setTemplateDialog(true);
  }
  
  return <>
    <MenuButton
      icon={<FileAdd />}
      onClick={handleDialogOpen}
    >
      {t("views.project.project_template.title")}
    </MenuButton>
    <CreateTemplateDialog
      projectUuid={uuid}
      open={templateDialog}
      onClose={() => setTemplateDialog(false)}
      defaultValues={{ description, name }}
      hasComments={hasComments}
    />
  </>
}

CreateTemplate.propTypes = {
  uuid: string.isRequired,
  name: string.isRequired,
  description: string,
  hasComments: bool,
}