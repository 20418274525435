import { useEffect, useRef, memo, useState } from "react";
import { bool, string, number, func, object, oneOf, oneOfType, node } from "prop-types";
import { NumericFormat } from "react-number-format";
import { capitalize, Tooltip } from "@material-ui/core";
import { Dialog } from "@components";
import classnames from "classnames";
import useStyle from "./ClearInput.style";

export const ClearInput = memo(({
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  style,
  addInitialBorder,
  prefix,
  suffix,
  disabled,
  className,
  autoFocus,
  fixedDecimalScale,
  fullWidth,
  maxWidth,
  clickDialogMessageContent,
  clickDialogMessageTitle,
  tabIndex,
  tooltipMessage,
  allowNegative=false,
  confirmOnEnter=true,
  alignContent="center",
  max=9999,
  min=0,
  color="primary",
  size="large",
  decimals=2,
}) => {
  
  const classes = useStyle();
  const [ localValue, setValue ] = useState(value?.toString());
  const [ focused, setFocused ] = useState(false);
  const [ tooltipVisible, showTooltip ] = useState(false);
  const [ tooltipClosed, setTooltipClosed ] = useState(false);
  
  const inputRef = useRef();
  
  const formatterProps = {
    thousandSeparator: " ",
    valueIsNumericString: true,
    allowNegative,
    decimalScale: fixedDecimalScale ? decimals : 0,
    prefix,
    suffix,
    value: localValue,
  }
  
  useEffect(() => {
    setValue(value?.toString() || "");
  }, [ value ]);
  
  const checkKey = e => {
    if ( clickDialogMessageContent ) return;
    const { key, target } = e;
    const { selectionStart, value } = target;
    if ( confirmOnEnter && key === "Enter" ) {
      e.stopPropagation();
      setFocused(true);
      // blur(e, true);
      inputRef.current.blur();
    }
    if ( key === "," ) {
      e.preventDefault();
      if ( target.value.includes(".") )
        return;
      target.value = `${ value.substr(0, selectionStart) }.${ value.substr(selectionStart, value.length) }`;
      const caretPos = target.value.indexOf(".") + 1;
      target.setSelectionRange(caretPos, caretPos);
    }
  };
  
  const focus = ( e ) => {
    if ( tooltipClosed ) {
      setTooltipClosed(false);
      setTimeout(() => (
        inputRef.current?.blur()
      ), 100);
      return;
    }
    
    if ( clickDialogMessageContent ) {
      e.preventDefault();
      showTooltip(true);
      return;
    }
    if ( !focused ) {
      onFocus && onFocus();
      setValue("");
      setFocused(true);
    }
  };
  
  const blur = ( e, fromEnterKey = false ) => {
    if ( clickDialogMessageContent ) return;
    if ( focused || fromEnterKey ) {
      onBlur && onBlur();
      setFocused(false);
      if ( typeof localValue !== "string" || localValue === "" ) {
        setValue(value?.toString());
        return;
      }
      if ( onChange ) {
        const cancelled = onChange(localValue ? parseFloat(localValue) : undefined);
        if ( cancelled )
          setValue(value?.toString());
      }
    }
  };
  
  const handleTooltipClose = () => {
    showTooltip(false);
    setTooltipClosed(true);
  };
  
  const onValueChange = ( values ) => {
    if ( clickDialogMessageContent ) return;
    if ( focused )
      setValue(values.floatValue === undefined ? undefined : Math.max(Math.min(max, values.floatValue || 0), min).toString());
  };
  
  const checkMinMax = ( { floatValue } ) => {
    if ( clickDialogMessageContent ) return false;
    return !(floatValue > max && localValue === max.toString())
      && !(floatValue < min && localValue === min.toString());
  };
  
  const inner = <div className="display-block h-full relative">
    {
      maxWidth &&
      <NumericFormat
        displayType="text"
        className={ classnames("transparent px-2 h-full display-block", classes.minWidth) }
        { ...formatterProps }
      />
    }
    <NumericFormat
      getInputRef={ inputRef }
      onValueChange={ onValueChange }
      className={ classnames(
        "noDrag",
        classes.root,
        classes[ alignContent ],
        classes.minWidth,
        {
          [ classes[ `color${ capitalize(color) }` ] ]: !disabled,
          [ classes.disabled ]: disabled,
          [ classes.disabledHover ]: disabled && !addInitialBorder,
          [ classes.fullWidth ]: fullWidth && !maxWidth,
          [ classes.maxWidth ]: maxWidth,
          [ classes.addInitialBorder ]: addInitialBorder,
          [ classes.sizeSmall ]: size === "small"
        }, className
      ) }
      onFocus={ focus }
      onBlur={ blur }
      onKeyDown={ checkKey }
      isAllowed={ checkMinMax }
      placeholder={ placeholder }
      disabled={ disabled }
      autoFocus={ autoFocus }
      style={ style }
      tabIndex={ tabIndex }
      // allowEmptyFormatting={false}
      { ...formatterProps }
    />
    {
      Boolean(clickDialogMessageContent) &&
      <Dialog
        open={ tooltipVisible }
        onClose={ handleTooltipClose }
        title={ clickDialogMessageTitle }
      >
        { clickDialogMessageContent }
      </Dialog>
    }
  </div>
  
  return tooltipMessage
    ? <Tooltip
      title={ tooltipMessage }
      disableHoverListener={ !tooltipMessage }
      disableFocusListener={ !tooltipMessage }
      disableTouchListener={ !tooltipMessage }
    >
      {inner}
    </Tooltip>
    : inner
});

ClearInput.propTypes = {
  value: oneOfType([ number, string ]),
  onChange: func.isRequired,
  onFocus: func,
  onBlur: func,
  placeholder: oneOfType([ string, number ]),
  alignContent: oneOf([ "left", "center", "right" ]),
  style: object,
  max: number,
  min: number,
  confirmOnEnter: bool,
  prefix: string,
  suffix: string,
  disabled: bool,
  className: string,
  autoFocus: bool,
  color: oneOf([ "primary", "text", "warning", "error" ]),
  addInitialBorder: bool,
  fixedDecimalScale: bool,
  allowNegative: bool,
  fullWidth: bool,
  maxWidth: bool,
  size: oneOf([ "small", "large" ]),
  tooltipMessage: oneOfType([ string, node ]),
  clickDialogMessageTitle: string,
  clickDialogMessageContent: string,
  tabIndex: number,
  decimals: number,
};
